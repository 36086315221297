// @flow
import React from 'react'

import ProfileCard from 'src/components/ProfileCard'
import Carousel from 'src/components/Carousel'

import PageRow from 'src/common/components/PageRow'

import styles from './ContentTeam.module.scss'

type Creator = {
  image: string,
  description: string,
  name: string,
  title: string,
}

type Props = {|
  content: {|
    heading: string,
    description: string,
    list: Array<Creator>,
  |},
|}

export default function ContentTeam({ content }: Props) {
  return (
    <PageRow>
      <div className={styles.container}>
        <div className={styles.header}>{content.heading}</div>
        <div className={styles.description}>{content.description}</div>
        <div className={styles.creators}>
          <Carousel>
            {content.list &&
              content.list.map(creator => (
                <ProfileCard
                  key={creator.name}
                  imageUrl={creator.image}
                  name={creator.name}
                  title={creator.title}
                  description={creator.description}
                />
              ))}
          </Carousel>
        </div>
      </div>
    </PageRow>
  )
}
