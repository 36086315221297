// @flow
import React from 'react'
import { graphql } from 'gatsby'

import { withIntl } from 'src/intl'

import {
  Layout,
  Footer,
  Navbar,
  BackgroundImage,
  BackgroundBlocks,
} from 'src/common/components'
import HeadTags from 'src/components/HeadTags'

import CertifiedResources from 'src/components/ContentRow/CertifiedResources'
import LandingPageHeader from 'src/components/ContentRow/LandingPageHeader'
import QuestionsAnswered from 'src/components/ContentRow/QuestionsAnswered'
import AppPreviewSection from 'src/components/ContentRow/AppPreviewSection'
import CourseDiscovery from 'src/components/ContentRow/CourseDiscovery'
import ExpertProfiles from 'src/components/ContentRow/ExpertProfiles'
import SenecaImpact from 'src/components/ContentRow/SenecaImpact'
import Testimonials from 'src/components/ContentRow/Testimonials'
import ContentTeam from 'src/components/ContentRow/ContentTeam'
import Partners from '../components/ContentRow/Partners'

type PageContext = {
  locale: string,
  pagePath: string,
}

type Props = {
  data: Object,
  pageTitle: string,
  seoTitle: string,
  metaDescription: string,
  publisherLink: string,
  pageContext: PageContext,
}

function TeacherPage({ data, pageContext }: Props) {
  const {
    meta,
    intro,
    hero,
    answers,
    impact,
    experts,
    discovery,
    creators,
    resources,
    testimonials,
    partners,
  } = data.markdownRemark.frontmatter

  const { footer } = data.footer.frontmatter

  return (
    <>
      <HeadTags pageContext={pageContext} {...meta} />
      <Layout>
        {{
          navbar: <Navbar pagePath={pageContext.pagePath} />,
          background: <BackgroundImage />,
          footer: <Footer content={footer} />,
          body: (
            <BackgroundBlocks>
              <LandingPageHeader
                heading={intro.heading}
                description={intro.description}
                list={intro.list}
              />
              <AppPreviewSection content={hero} />
              <QuestionsAnswered content={answers} />
              <SenecaImpact content={impact} />
              <ExpertProfiles content={experts} />
              <CourseDiscovery content={discovery} />
              <ContentTeam content={creators} />
              <CertifiedResources content={resources} />
              <Testimonials content={testimonials} />
              {partners.showSection && <Partners content={partners} />}
            </BackgroundBlocks>
          ),
        }}
      </Layout>
    </>
  )
}

export default withIntl(TeacherPage)

export const TeachersPageQuery = graphql`
  query TeachersQuery($id: String!, $locale: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ...MetaFragment
        ...LandingIntroFragment
        ...LandingHeroFragment
        ...LandingAnswersFragment
        ...LandingImpactFragment
        ...LandingDiscoveryFragment
        experts {
          heading
          description
          button {
            text
            external
            openInNewTab
            linkExternal
            linkInternal
          }
          list {
            name
            title
            description
            image
          }
        }
        creators {
          heading
          description
          list {
            name
            image
            title
            description
          }
        }
        resources {
          heading
          description
          button {
            text
            external
            openInNewTab
            linkExternal
            linkInternal
          }
        }
        testimonials {
          heading
          list {
            image
            testimonial
            author
            position
          }
        }
        ...LandingPartnersFragment
      }
    }
    ...FooterFragment
  }
`
