// @flow
import React from 'react'

import { ButtonLink, type ButtonProps } from 'src/common/components/Button'
import PageRow from 'src/common/components/PageRow'
import PostContent from 'src/common/components/PostContent'

import styles from './CertifiedResources.module.scss'

type Content = {|
  heading: string,
  description: string,
  button: ButtonProps,
|}

type Props = {|
  content: Content,
|}

export default function CertifiedResources({
  content: { heading, description, button },
}: Props) {
  return (
    <PageRow>
      <div className={styles.content}>
        <PostContent className={styles.header} content={heading} />
        <div className={styles.description}>
          <PostContent content={description} />
        </div>
        <ButtonLink color="blue" {...button} />
      </div>
    </PageRow>
  )
}
