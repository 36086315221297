// @flow

import React from 'react'

import { ButtonLink, type ButtonProps } from 'src/common/components/Button'
import ProfileCard from 'src/components/ProfileCard'
import PostContent from 'src/common/components/PostContent'
import PageRow from 'src/common/components/PageRow'

import styles from './ExpertProfiles.module.scss'

type ExpertProps = {|
  image: string,
  name: string,
  title: string,
  description: string,
|}

type ContentProps = {|
  heading: string,
  description: string,
  button: ButtonProps,
  list: Array<ExpertProps>,
|}

type Props = {|
  content: ContentProps,
|}

export default function ExpertProfiles({
  content: { heading, description, button, list },
}: Props) {
  return (
    <PageRow>
      <div className={styles.container}>
        <div className={styles.header}>{heading}</div>
        <div className={styles.description}>
          <PostContent content={description} />
        </div>
        <ButtonLink color="blue" {...button} />
        <div className={styles.experts}>
          {list &&
            list.map(item => (
              <ProfileCard
                key={item.name}
                name={item.name}
                imageUrl={item.image}
                title={item.title}
                description={item.description}
              />
            ))}
        </div>
      </div>
    </PageRow>
  )
}
